import React, { Component } from 'react';

import moment from 'moment';
//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';
import Link from '../_core/components/Link';
import Pagination from '../_core/components/Pagination';
import Icon from '../_core/components/Icon';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
		this.state = {active:[0]};
	}

	componentDidMount() {

	}

	toggle = (ix) => {

		let active = [...this.state.active];
		
		// Not in the array, push it on...
		if(active.indexOf(ix) == -1){
			active.push(ix);
		}else{
		// Filter it out
			active = this.state.active.filter(i => (ix != i));
		}

		this.setState({active : active});

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];

		let isHome = entry.uri == '/';

		let item;
	
		return (
	
				<Template modifiers={[entry.style,entry.type,entry.template,'blocks']} setTitle={false} title={entry.title}>

				<FadeIn duration={0.4} delay={0.1} key={entry.id}>
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				{(()=>{

	                if(isHome){
	                  return (<SiteModifier auto modifier={'home'} />);
	                }else{
	                	return (<SiteModifier auto demodifier={'home'} />);
	                }

	              })()}

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>{ entry.title }</h1>
						</div>
					</div>
					
					{(()=>{

	                if(entry.parent){

	                	let segments = window.location.pathname.replace(/^[\/]/g, "").replace(/[\/]$/g, "").split('/');
	                	let uri = '/';

	                	let crumbs = [{
	                		uri : '/',
	                		title : 'Home'
	                	}];
	                	segments.forEach((s)=>{

	                		uri +=  s + '/';
	                		crumbs.push({
	                			uri : uri,
	                			title: s.replace(/\-/g,' ')
	                		})

	                	});

	                  return (
	                  	<div className="Template-breadcrumbs">
	                  		<ul className="List List--breadcrumbs">
	                  		{crumbs.map((item) => {

	                  			return (
	                  			<li><Link to={item.uri}>{item.title}</Link></li>
	                  			)
	                  		})}
	                  		</ul>
	                  	</div>);
	                }

	              })()}

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Data>
							{
								({entries,SORT}) => {

									let source = entry.source;

									let items = [];
									if(source == 'childPages') items = entries.filter(e=>(e.parent == entry.id));
									if(source == 'relatedPages') items = entries.filter(e=>(entry.related.indexOf(e.id) != -1));
									
									let sortby = (SORT) ? SORT : entry.defaultOrder;

									

									switch(sortby){

										case 'A-Z':
										items.propSort('title',1);
										break;
										case 'Z-A':
										items.propSort('title',-1);
										break;
										case 'New-Old':
											if(entry.dateSource == 'startEnd'){
												items.propSort('startDate',-1);
											}else if(entry.dateSource == 'endDate'){
												items.propSort('endDate',-1);
											}else{
												items.propSort('postDate',-1);
											}
											
										break;
										case 'Old-New':
											if(entry.dateSource == 'startEnd'){
												items.propSort('startDate',1);
											}else if(entry.dateSource == 'endDate'){
												items.propSort('endDate',1);
											}else{
												items.propSort('postDate',1);
											}
										break;

									}

										
					

									blocks = [];
									let groupBlocks = [];

									// Add a text block for the page
									if(entry.text){
										
										let intro = {
											type : 'sort',
											text : entry.text,
											style : 'default',
											size: 'full'
										};
										
										blocks.push(intro);
										groupBlocks.push(intro);
									}

									// Log year groups as we go
									let groups = [];
									let groupMin = 0;
									let groupMax = 0;



									// Convert items into blocks (note: must have thumbnail!)
									let itemBlocks = items.map(i => {
										let date = null;
										let d,start,end; 
										let itemGroup = 0;

										if(entry.showDate){

											let fallback = i.postDate;
											if(!i.startDate) i.startDate = fallback;
											if(!i.endDate) i.endDate = fallback;

											let format = 'DD MMM YYYY';
											switch(entry.dateFormat){
												case 'monthYear':
												format = 'MMM YYYY';

												break;
											}

											switch(entry.dateSource){

												case "startEnd":
													d = moment(i.startDate);
													start = moment(i.startDate);
													end = moment(i.endDate);

													date = {
														from : i.startDate,
														to : i.endDate,
														format : format,
														groupStart : start.format('YYYY'),
														groupEnd : end.format('YYYY')
													}
													
												break;
												case "endDate":
													d = moment(i.endDate);
													start = moment(i.startDate);
													end = moment(i.endDate);

													date = {
														from : i.startDate,
														to : i.endDate,
														format : format,
														groupStart : start.format('YYYY'),
														groupEnd : end.format('YYYY')
													}
													
												break;
												default:
													d = moment(i.postDate);
													start = moment(i.postDate);
													end = moment(i.postDate);
													date = {
														from : i.postDate,
														to : i.postDate,
														format : format,
														groupStart : start.format('YYYY'),
														groupEnd : end.format('YYYY')
													}
													
												break;
											}



											// Make a group based on the format of the source year
											itemGroup = d.format('YYYY');

											// Update min and max groups based on this year
											let groupStart;
											let groupEnd;

											switch(entry.groupBy){

												case 'startDate':
													groupStart = parseInt(date.groupStart);
											 		groupEnd = parseInt(date.groupStart);
												break;
												case 'endDate':
													groupStart = parseInt(date.groupEnd);
											 		groupEnd = parseInt(date.groupEnd);
												break;
												default:
													groupStart = parseInt(date.groupStart);
											 		groupEnd = parseInt(date.groupEnd);
												break;

											}

											// overwrite the values because these ar eusaed to work out whether to display the entries or not
											date.groupStart = groupStart;
											date.groupend = groupEnd;
										

											if(!groupMin || (groupStart < groupMin)) groupMin = groupStart;
											if(!groupMax || (groupEnd > groupMax)) groupMax = groupEnd;

											//if(groups.indexOf(itemGroup) == -1) groups.push(itemGroup);

										}
									return ({
										type : 'item',
										group : itemGroup,
										image : (i.thumbnail.length) ? i.thumbnail[0] : null,
										linkTo : [i.id],
										heading : `<h1>${i.title}</h1>`,
										date : date,
										text : `<p>${i.metaDescription || ''}</p>`,
										style : `default`,
										size: 'onethird',
										callToAction : entry.callToAction || 'Read More'
									})
									});

									if(entry.showDate && entry.groupByYear){

										// Build array for all groups

										for (var i = groupMin; i <= groupMax; i++) {
										    groups.push(i);
										}

										// Reverse the groups if defautl isting order is new-old
										if(sortby == 'New-Old' || (entry.defaultOrder == 'New-Old' && sortby != 'Old-New')) groups.reverse();

										

										return (
											<React.Fragment>

												<Project.Blocks blocks={[...groupBlocks]} entry={entry} key={sortby} modifiers={[]} />
												
												{
													groups.map(
														(y,ix)=>{

															let groupBlocks = itemBlocks.filter((i)=>((i.date.groupStart <= y) && (i.date.groupEnd >= y)));
															let isActive = this.state.active.indexOf(ix) != -1;

															// Empty group
															if(!groupBlocks.length) return null;

															return (

															<div className={`Item Item--accordion ${isActive ? 'is-active' : 'is-inactive'}`}  key={'group'+ix}>
																<div className="Item Item-head" onClick={()=>this.toggle(ix)}><span>{y}</span><div className="Item-arrow"><Icon glyph type="down" /></div> </div>
																{
																	(()=>{
																		
																		if(isActive){
																			return <div className="Item Item-body">
																			<FadeIn key={y+sortby}>
																				<Pagination perPage={12} items={groupBlocks} loadText={`More from ${y}`} render={(paginated)=>(
																					<Project.Blocks blocks={paginated} entry={entry}  modifiers={[]} />
																				)} />
																			</FadeIn>
																			</div>
																		}

																	})()
																}
																
															</div>
															)

														}
													)
												}
												
											</React.Fragment>)

									}else{
										
										blocks = blocks.concat(itemBlocks);
										return <Project.Blocks blocks={[...blocks]} entry={entry} key={sortby} modifiers={[]} />

									}

								}
							}
							
							</Data>
						</div>
					</div>
				</FadeIn>
				</Template>
		)

	}
}

export default Default;
