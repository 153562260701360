import React, { Component } from 'react';

// GA Event Logger
import GoogleAnalytics from 'react-ga';
import {Form} from '../../_core/components';

class Newsletter extends Component {


  render() {
    return (

          <Form
          target={'_blank'}
          modifier={'newsletter'}
          onSubmit={() => {

              GoogleAnalytics.event({
                  category: 'Subscriptions',
                  action: 'Subscription Submission'
              });

          }}
          ajax={false}
          action={'https://acme.us15.list-manage.com/subscribe'}
          fields={[
              {
                type:'hidden',
                name : 'u',
                value : '0f64850e0060306d52ab097dd'
              },
              {
                type:'hidden',
                name : 'id',
                value : 'c0ae3a8ac0'
              },
              {
                name: 'MERGE0',
                type: 'email',
                placeholder: this.props.callToAction || 'Sign up to our newsletter'
              }
          ]}>
          </Form>
    );
  }
}

export default Newsletter;
