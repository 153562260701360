import React, { Component } from "react";
import Base from "../BaseComponent";
import PageTitle from "../PageTitle";

import { withData, withSite } from "../../utils";

class Template extends Component {
  render() {
    let setTitle = true;
    if (this.props.hasOwnProperty("setTitle")) setTitle = this.props.setTitle;

    return (
      <Base modifiers={this.props.modifiers} family="Template">
        {(() => {
          if (setTitle) {
            return <PageTitle title={this.props.title} />;
          }
        })()}
        {this.props.children}
      </Base>
    );
  }
}

export default withSite(Template);
