import React, { Component } from "react";

// Conf
import conf from "../_core/_conf";

// Core
import Loader from "../_core/components/Loader";
import Media from "../_core/components/Media";
import ViewportProgress from "../_core/components/ViewportProgress";
import JumpLink from "../_core/components/JumpLink";
import ScrollModifier from "../_core/components/ScrollModifier";
import Image from "../_core/components/Image";
import Link from "../_core/components/Link";
import PageElement from "../_core/components/PageElement";
import Site from "../_core/components/Site";
import ScrollTo from "../_core/components/ScrollTo";
import Burger from "../_core/components/Burger";
import Icon from "../_core/components/Icon";
import Content from "../_core/components/Content";
import Transition from "../_core/components/Transition";
import SiteModifier from "../_core/components/SiteModifier";
import NormalizedScroller from "../_core/components/NormalizedScroller";
import MousePos from "../_core/components/MousePos";
import FadeIn from "../_core/components/FadeIn";
import Filter from "../_core/components/Filter";

import { Entry, Asset, Data } from "../_core/models";
import { Template, Modal } from "../_core/controllers";
import { withStore, withSite } from "../_core/utils";

import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

// Project
import * as Project from "../components";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { scrollDelta: 0, scrollUpdates: 0 };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let _this = this;

    let HISTORY = this.props.history;

    return (
      <Data>
        {({
          footer,
          ENTRY,
          entries,
          assets,
          caption,
          introComplete,
          globals,
        }) => {
          return (
            <div className="Site-page" id="page">
              <Link
                to={window.location.pathname + "#back"}
                className="Site-page-close"
              />
              <ScrollModifier reverse={false} />
              <SiteModifier auto modifier={"preloaded"} />

              {/* Site Content */}
              <div className="Site-content">
                <SiteModifier demodifier={"filtering"}>
                  <div className="Site-template">
                    <ScrollTo
                      useHistory={true}
                      auto
                      offset={document.getElementById("head").offsetHeight}
                      ignore={({ pathname }, history) => {
                        // Return to top in all cases except when the hash matches filter or back
                        return (
                          !!window.location.hash.match("filter") ||
                          !!window.location.hash.match("back")
                        );
                      }}
                    />

                    <Template />
                  </div>
                </SiteModifier>
              </div>
              {/* End Site Content */}

              {/* Site Foot */}
              <div
                id="footer"
                className={ 
                  ENTRY.slug === "acme-50" || ENTRY.slug === "acme-propeller-factory"  || ENTRY.slug === "acme-propeller-factory-campaign"
                  ? "Site-foot hide-map" : "Site-foot"
                }
              >
                <div className="Site-foot-newsletter">
                  <Project.Newsletter />
                </div>

                <div className="Site-foot-contact">
                  <div data-grid-of="2">
                    <div>
                      <Content modifier="footer">
                        <p>
                          44 Copperfield Road, Bow
                          <br />
                          London E3 4RR
                        </p>
                      </Content>
                    </div>
                    <div>
                      <Content modifier="footer">
                        <p>
                          E:{" "}
                          <a href="mailto:mail@acme.org.uk">mail@acme.org.uk</a>
                          <br />
                          T: +44 (0)20 8981 6811
                        </p>
                      </Content>
                    </div>
                  </div>
                </div>
                <div className="Site-foot-social">
                  <ul className="List List--icons">
                    {globals.links
                      .find((l) => l.label == "social")
                      .links.map((item) => (
                        <li>
                          <a target="_blank" href={item.customLink}>
                            <Icon glyph type={item.text} />
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="Site-foot-nav">
                  <Content modifier="footer">
                    <ul className="List List--inline">
                      {footer.map((e) => (
                        <li>
                          <Link to={e.uri}>{e.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </Content>
                </div>

                <div className="Site-foot-logos">
                  <ul className="List List--inline">
                    {globals.links
                      .find((l) => l.label == "logos")
                      .links.map((item) => (
                        <li>
                          <a target="_blank" href={item.customLink}>
                            <Asset
                              id={item.image}
                              render={(a) => (
                                <img
                                  title={item.text}
                                  alt={item.text}
                                  src={a.sizes.small}
                                />
                              )}
                            />
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              <div
                className={
                  ENTRY.slug === "acme-50" || ENTRY.slug === "acme-propeller-factory" || ENTRY.slug === "acme-propeller-factory-campaign" 
                    ? "Site-foot Site-foot--signoff hide-map"
                    : "Site-foot Site-foot--signoff"
                }
              >
                <div className="Site-foot-copy">
                  <Content modifier="footer">
                    <p>
                    Acme Artists' Studios Ltd. Reg No. IP030662.
                    <br />
                    A charity registered under the Co-operative and Community Benefits <br />Societies Act 2014 (Mutuals Public Register No. 30662R).
                    </p>
                  </Content>
                </div>
                <div className="Site-foot-credit">
                  <Content modifier="footer">
                    <p>
                      &copy; {new Date().getFullYear()}. Design by{" "}
                      <a href="http://e-i-b.com">Everything In Between</a>
                    </p>
                  </Content>
                </div>
              </div>
            </div>
          );
        }}
      </Data>
    );
  }
}

export default withRouter(withStore(Layout));
