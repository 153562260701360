import React, { Component } from "react";

class EqualHeight extends Component {
  componentDidMount() {
    this.run();

    window.addEventListener("resize", this.run);
  }

  componentDidUpdate() {
    this.run();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.run);
  }

  run = () => {
    let selector = this.props.selector || ".ui-equalHeight";
    let $els = Array.from(document.querySelectorAll(selector));

    this.matchHeight($els);
  };

  checkImages = ($el) => {
    let $imgs = Array.from($el.querySelectorAll("img"));

    $imgs.forEach(($img) => {
      let seen = $img.getAttribute("data-equalheight-img-seen");
      if (typeof seen != "undefined" && seen != null) return;

      $img.setAttribute("data-equalheight-img-seen", true);

      $img.addEventListener("load", () => {
        this.run();
      });
    });
  };

  matchHeight = ($els) => {
    let _this = this;

    $els.forEach(($el) => {
      $el.style.height = null;
    });

    let highest = 0;
    let offsetTop = 0; // use this to only set heights of things that start at the same point. this sizes things in rows.

    // TODO: make offset checking optional

    let group = 0;
    let groups = {};
    let setHeights = {};
    let highests = {};
    let key = "offset" + 0;

    let i = 0;

    $els.forEach(($el) => {
      let offset = Math.round($el.getBoundingClientRect().top);

      _this.checkImages($el);

      $el.setAttribute("data-offset-top", offset);

      let useOffset = true;

      let data = $el.getAttribute("data-equalheight-useoffset");
      if (typeof data != "undefined" && data != null) useOffset = data;

      if (!useOffset) offset = 1; // force offset to 1

      if (offset != offsetTop) {
        key = "offset" + offset;
        i++;
        offsetTop = offset;
        highest = 0; // reset highest is the offset has changed, so sizes in rows

        //console.log(highests);

        if (typeof highests[key] == "undefined") {
          groups[key] = [];
          highests[key] = 0;
        }

        groups[key].push($el);
      }
      let h = $el.offsetHeight;
      highests[key] = Math.max(h, highests[key]);
      $el.setAttribute("data-equalHeight-group", i);
      $el.setAttribute("data-equalHeight-key", key);

      setHeights[key] = highests[key];
    });
    //console.log(setHeights);
    $els.forEach(($el) => {
      let group = $el.getAttribute("data-equalHeight-group");
      let key = $el.getAttribute("data-equalHeight-key");
      $el.setAttribute("data-get-group", group);
      $el.setAttribute("data-get-key", key);

      let height = setHeights[key];
      $el.style.height = height + "px";

      $el.style.borderBottom = "1px solid #000";
    });
  };

  render() {
    let className = this.props.className || "";
    className += " " + "ui-equalHeight";

    return <div className={className}>{this.props.children}</div>;
  }
}

export default EqualHeight;
