import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		
		return (
	
				<Template modifiers={['404','blocks']} title={'Page Not Found'}>

				<FadeIn duration={0.4} delay={0.1} key={'error'}>
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				<SiteModifier auto demodifier={'home'} />
	
					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks blocks={[{
								type : 'text',
								text : `<h1>Page Not Found</h1><h2>We can't seem to find that page. We have recently changed our site, perhaps the page you were looking for has moved.</h2><h2>Try our <a href="/">Home page</a> or <a href="/content/">get in touch</a>.</h2>`,
								size : 'full'

							}]} modifiers={[]} />
						</div>
					</div>
				</FadeIn>
				</Template>
		)

	}
}

export default Default;
