import React, { Component } from "react";

/*import {SiteModifier,Icon,Link,Base,Map,List,IsVisible,Media,Content,Grid} from '../../_core/components';*/

import Icon from "../../_core/components/Icon";
import Link from "../../_core/components/Link";
import Base from "../../_core/components/BaseComponent";
import Map from "../../_core/components/Map";
import List from "../../_core/components/List";
import IsVisible from "../../_core/components/IsVisible";
import Media from "../../_core/components/Media";
import Content from "../../_core/components/Content";
import Grid from "../../_core/components/Grid";
import Nl2br from "../../_core/components/Nl2br";
import SiteModifier from "../../_core/components/SiteModifier";
import EqualHeight from "../../_core/components/EqualHeight";
import DateFormat from "../../_core/components/DateFormat";
import Toggle from "../../_core/components/Toggle";

import { Entry, Asset, Data } from "../../_core/models";
import { Each } from "../../_core/utils";
import { withStore } from "../../_core/utils/Store";

import "../../_core/helpers/String";

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

// Project
import * as Project from "../../components";

// Conf
import conf from "../../_core/_conf";

class Blocks extends Component {
  sections = [];
  componentDidMount = () => {};
  cleanUri = (uri) => {
    let settings = conf.get();
    return uri.replace(settings.CDN_HOSTNAME, "");
  };
  makeBlocks = () => {
    this.section = [];
    let blocks = this.props.blocks || [];

    if (!blocks.length) {
      this.sections = [];
      return;
    }

    // Get the background positions...
    if (blocks[0].type != "background")
      blocks.unshift({
        type: "background",
        style: "none",
      });

    let sections = [];
    let section = -1;

    blocks.forEach((block) => {
      if (block.type == "background") {
        section++;
        sections[section] = { ...block, blocks: [] };
      } else {
        sections[section].blocks.push(block);
      }
    });

    this.sections = sections;
  };
  getBlock = (origBlock, entry) => {
    let _this = this;
    let SETTINGS = conf.get();

    let block = { ...origBlock };

    switch (block.type) {
      case "global":
        if (!block.block || !block.block.length) return null;
        return (
          <Entry
            id={block.block}
            render={(globalBlock) => {
              if (!globalBlock) return null;
              return (
                <Each
                  items={globalBlock.blocks}
                  render={(item) => {
                    // Copy size from the current page's block into the global block
                    let b = { ...item, size: block.size };

                    return _this.getBlock(b, entry);
                  }}
                />
              );
            }}
          />
        );

      case "divider":
        return (
          <div
            className={"Block Block--divider Block--" + block.size}
            data-type={block.type}
          />
        );

      case "anchor":
        return (
          <a
            className={"Block Block--anchor"}
            data-type={block.type}
            id={block.label}
            name={block.label}
          />
        );

      case "blank":
        return (
          <div
            className={"Block Block--blank Block--" + block.size}
            data-type={block.type}
          />
        );

      case "embed":
        return (
          <div
            className={"Block Block--embed Block--" + block.size}
            data-type={block.type}
          >
            <div className="Block-iframe ui-equalHeight">
              <iframe
                width={"100%"}
                height={"100%"}
                src={block.source}
                border={"none"}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                frameborder="0"
              />
            </div>
          </div>
        );

      case "item":
        return (
          <Asset
            id={[block.image]}
            results={(assets) => {
              let link =
                block.linkTo && block.linkTo.length
                  ? _this.props.data.entries.find(
                      (e) => e.id == block.linkTo[0]
                    ).uri
                  : block.customLink;

              return (
                <EqualHeight
                  className={
                    "Block Block--item Block--" +
                    block.size +
                    " Block--orientation-" +
                    (assets.length ? assets[0].orientation : "default") +
                    " Block--" +
                    block.style +
                    " Block--" +
                    (assets.length ? "hasImage" : "noImage") +
                    " Block--" +
                    (link ? "hasLink" : "noLink")
                  }
                  data-type={block.type}
                >
                  <div className="Block-content">
                    {(() => {
                      if (link) {
                        let styling = block.style;
                        return (
                          <Link
                            className={"Item Item--block Item--" + block.aspect}
                            to={link}
                          >
                            <div className="Item-content">
                              {(() => {
                                if (assets.length) {
                                  return (
                                    <div className="Item-media">
                                      <Media
                                        asset={assets[0]}
                                        fluid={false}
                                        ratio={6 / 4}
                                        style={styling}
                                      />
                                    </div>
                                  );
                                }
                              })()}

                              <div className="Item-head">
                                <Content html={block.heading} />
                              </div>
                              {(() => {
                                if (block.date && block.date.from) {
                                  return (
                                    <div className="Item-date">
                                      <Content>
                                        <h3>
                                          <DateFormat
                                            time={block.date.from}
                                            to={block.date.to}
                                            format={
                                              block.date.format || "DD MMM YYYY"
                                            }
                                          />
                                        </h3>
                                      </Content>
                                    </div>
                                  );
                                }
                              })()}
                              <div className="Item-body">
                                <Content html={block.text} />
                              </div>
                              {(() => {
                                if (block.callToAction) {
                                  return (
                                    <div className="Item-foot">
                                      {block.callToAction}
                                    </div>
                                  );
                                }
                              })()}
                            </div>
                          </Link>
                        );
                      } else {
                        let styling = block.style;
                        return (
                          <div
                            className={"Item Item--block Item--" + block.aspect}
                          >
                            <div className="Item-content">
                              {(() => {
                                if (assets.length) {
                                  return (
                                    <div className="Item-media">
                                      <Media
                                        asset={assets[0]}
                                        fluid={false}
                                        ratio={6 / 4}
                                        style={styling}
                                      />
                                    </div>
                                  );
                                }
                              })()}

                              <div className="Item-head">
                                <Content html={block.heading} />
                              </div>
                              {(() => {
                                if (block.date && block.date.from) {
                                  return (
                                    <div className="Item-date">
                                      <Content>
                                        <h3>
                                          <DateFormat
                                            time={block.date.from}
                                            to={block.date.to}
                                            format={"DD.MM.YYYY"}
                                          />
                                        </h3>
                                      </Content>
                                    </div>
                                  );
                                }
                              })()}
                              <div className="Item-body">
                                <Content html={block.text} />
                              </div>
                              {(() => {
                                if (block.callToAction) {
                                  return (
                                    <div className="Item-foot">
                                      {block.callToAction}
                                    </div>
                                  );
                                }
                              })()}
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </EqualHeight>
              );
            }}
          />
        );

      case "items":
        return (
          <div className="Block Block--items Block--full">
            <div className="Block-content">
              <Content html={block.text} />
            </div>
            <div className={"Block-items Block-items--" + block.itemSize}>
              <div className="Block-item-grid">
                {block.items.map((i) => {
                  return (
                    <div className={"Block-item Block-item--" + block.itemSize}>
                      <div className="Item Item--basic">
                        <div className="Item-media">
                          <Media id={i.image[0]} fluid={false} ratio={6 / 4} />
                        </div>
                        <div className="Item-head">
                          <Content html={i.text} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );

      case "definitionList":
        return (
          <EqualHeight
            className={
              "Block Block--definitionList Block--" +
              block.size +
              " Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              <dl className="List List--definition">
                {block.items.map((i) => (
                  <li>
                    <dt>
                      <Content html={i.heading} />
                    </dt>
                    <dd>
                      <Content html={i.text} />
                    </dd>
                  </li>
                ))}
              </dl>
            </div>
          </EqualHeight>
        );

      case "gallery":
        let images = block.images || [];
        let autoplay = block.autoplay || false;

        // Gallery -> Carousel
        if (block.style == "carousel") {
          let gsettings = {
            slidesToShow: 1,
            variableWidth: true,
          };

          gsettings.variableWidth = false;
          gsettings.centerMode = false;

          return (
            <EqualHeight
              className={
                "Block Block--carousel Block--" +
                block.style +
                " Block--" +
                block.size
              }
              data-type={block.type}
              key={JSON.stringify(images)}
              imageIds={block.image}
            >
              <div className="Block-content">
                <Project.CarouselGallery
                  modifiers={[block.style, block.size]}
                  settings={gsettings}
                >
                  {images.map((image) => (
                    <div className="CarouselGallery-item">
                      <Asset
                        id={image.image}
                        render={(asset) => {
                          return (
                            <div
                              className={
                                "Item Item--gallery Item--" + image.aspect
                              }
                            >
                              <div className="Item-media">
                                <Media
                                  asset={asset}
                                  fluid={false}
                                  ratio={6 / 4}
                                />
                              </div>
                              {(() => {
                                if (image.showCaption) {
                                  return (
                                    <div className="Item-body">
                                      <div className="Item-caption">
                                        <Content
                                          modifier="caption"
                                          html={image.caption}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              })()}
                            </div>
                          );
                        }}
                      />
                    </div>
                  ))}
                </Project.CarouselGallery>
              </div>
            </EqualHeight>
          );
        }

        // Gallery -> FullCarousel
        if (block.style == "fullheight") {
          let gsettings = {
            slidesToShow: 1,
            variableWidth: true,
            autoplay: autoplay,
            arrows: !autoplay,
          };

          gsettings.variableWidth = false;
          gsettings.centerMode = false;

          return (
            <EqualHeight
              className={
                "Block Block--carousel Block--" +
                block.style +
                " Block--" +
                block.size
              }
              data-type={block.type}
              key={JSON.stringify(images)}
            >
              <div className="Block-content">
                <Project.CarouselGallery
                  modifiers={[block.style, block.size]}
                  settings={gsettings}
                >
                  {images.map((image) => (
                    <div className="CarouselGallery-item">
                      <Asset
                        id={image.image}
                        render={(asset) => {
                          return (
                            <div
                              className={
                                "Item Item--gallery Item--" + image.aspect
                              }
                            >
                              <div className="Item-media">
                                <Media
                                  asset={asset}
                                  fluid={false}
                                  ratio={6 / 4}
                                  style={block.style}
                                />
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>
                  ))}
                </Project.CarouselGallery>
              </div>
            </EqualHeight>
          );
        }

        // Gallery -> Not carousel

        return images.map((image) => (
          <Asset
            id={image.image}
            render={(asset) => {
              let ratio = 6 / 4;
              if (block.aspect && block.aspect == "portrait") ratio = 4 / 6;
              if (block.aspect && block.aspect == "natural")
                ratio = asset.width / asset.height;

              return (
                <div
                  key={image.image[0]}
                  className={
                    `Block Block--image Block--${
                      block.size || "full"
                    } Block--orientation-` + asset.orientation
                  }
                  data-type={block.type}
                >
                  <div className="Block-content">
                    <div className={"Item Item--gallery Item--" + image.aspect}>
                      <div className="Item-media">
                        <Media asset={asset} fluid={false} ratio={ratio} />
                      </div>
                      {(() => {
                        if (image.showCaption) {
                          return (
                            <div className="Item-body">
                              <div className="Item-caption">
                                <Content
                                  modifier="caption"
                                  html={image.caption}
                                />
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        ));

      case "text":
        let style = block.style || "default";
        return (
          <EqualHeight
            className={
              "Block Block--text Block--" +
              block.size +
              "  Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  return (
                    <div className="Block-heading">
                      <Nl2br text={block.heading} />
                    </div>
                  );
                }
              })()}

              {/* Text / Article */}
              <Grid modifiers={["article"]}>
                <div className="Grid-item">
                  <Content
                    modifier={"article Content--" + style}
                    html={block.text}
                  />
                </div>
                {(() => {
                  if (block.caption && !block.caption.match("data-mce-bogus")) {
                    return (
                      <div className="Grid-item Grid-item--caption">
                        <Content modifier="caption" html={block.caption} />
                      </div>
                    );
                  }
                })()}
              </Grid>

              <Grid modifiers={["links"]}>
                <Each
                  items={block.links}
                  render={(item) => {
                    let uri =
                      item.customLink && item.customLink.length
                        ? item.customLink
                        : this.cleanUri(item.linkTo);
                    return (
                      <div className="Grid-item">
                        <Link
                          to={uri}
                          className={
                            "Link Link--callToAction Link--" + block.style
                          }
                          deactivate={"#nav"}
                        >
                          <span className="Link-body">{item.callToAction}</span>
                        </Link>
                      </div>
                    );
                  }}
                />
              </Grid>
            </div>
          </EqualHeight>
        );

      case "sort":
        return (
          <div
            className={
              "Block Block--text Block--" +
              block.size +
              "  Block--" +
              (block.style || "default")
            }
            data-type={block.type}
          >
            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  return (
                    <div className="Block-heading">
                      <Nl2br text={block.heading} />
                    </div>
                  );
                }
              })()}

              {/* Text / Article */}
              <Grid modifiers={["article"]}>
                <div className="Grid-item">
                  <Content
                    modifier={"article Content--" + style}
                    html={block.text}
                  />
                </div>
                <div className="Grid-item Grid-item--sort">
                  <Data>
                    {({ SORT }) => {
                      return (
                        <Toggle autoclose tag={"div"} className="Dropdown">
                          <div className="Dropdown-head">
                            {SORT ? `${SORT}` : `Sort`}
                          </div>
                          <div className="Dropdown-arrow">
                            <Icon glyph type="down" />
                          </div>
                          <div className="Dropdown-body">
                            <ul className="List List--dropdown">
                              <li>
                                <Link navlink to={"#/sort/Old-New/"}>
                                  Old—New
                                </Link>
                              </li>
                              <li>
                                <Link navlink to={"#/sort/New-Old/"}>
                                  New—Old
                                </Link>
                              </li>
                              <li>
                                <Link navlink to={"#/sort/A-Z/"}>
                                  A—Z
                                </Link>
                              </li>
                              <li>
                                <Link navlink to={"#/sort/Z-A/"}>
                                  Z—A
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Toggle>
                      );
                    }}
                  </Data>
                </div>
              </Grid>
            </div>
          </div>
        );

      case "map":
        return (
          <EqualHeight
            className={
              "Block Block--map Block--" +
              block.size +
              "  Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              <Map lat={block.latitude} lng={block.longitude} />
            </div>
          </EqualHeight>
        );

      default:
        return (
          <div
            data-type={block.type}
            className={
              "Block Block--" +
              block.type +
              " Block--unknown Block--" +
              block.size
            }
          >
            {JSON.stringify(block)}
          </div>
        );
    }
  };
  render() {
    this.makeBlocks();
    //console.log('blocks',this.sections);

    let { sections } = this;
    let modifiers = this.props.modifiers || [];
    let entry = this.props.entry || false;

    return (
      <Base family="Blocks" modifiers={modifiers}>
        {sections.map((section, ix) => {
          return (
            <div
              key={entry.id + "_" + ix}
              className={"Blocks-section Blocks-section--" + section.style}
            >
              {section.blocks.map((block) => {
                return this.getBlock(block, entry);
              })}
            </div>
          );
        })}
      </Base>
    );
  }
}

function Block(block) {}

export default withStore(Blocks);
