import GoogleAnalytics from "react-ga";
const MAPSTYLES = require("./json/map.json");
const IS_PRODUCTION = window.location.hostname.match("acme.org.uk");
const CDN_HOSTNAME = "//acme.org.uk";
const hostName = IS_PRODUCTION ? CDN_HOSTNAME : "";
const d = new Date();
const CACHEBUST = true;
let CACHEBUSTER = IS_PRODUCTION
	? "?nc=" + d.getFullYear() + d.getMonth() + d.getDate() + d.getHours()
	: "?nc=" + d.getTime();
CACHEBUSTER = CACHEBUST ? CACHEBUSTER : "";
let staticJSON = IS_PRODUCTION;
let HOST = staticJSON ? "/data/" : "/json/";

const settings = {
	SITENAME: "ACME",
	CDN_HOSTNAME: CDN_HOSTNAME,
	MANIFEST: [
		{
			name: "entries",
			src: HOST + "entries.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "assets",
			src: HOST + "assets.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "navigation",
			src: HOST + "navigation.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "filters",
			src: HOST + "filters.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "footer",
			src: HOST + "footer.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "globals",
			src: HOST + "globals.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
			transformer: (data) => data[0],
		},
	],
	GOOGLE_MAPS_API_KEY: "AIzaSyBO-F_0wgmHcwJ2sPhrc9Dyt2fKG3SKHfM",
	MAPSTYLES: MAPSTYLES,
	GA_ID: "UA-5943322-1",
	GTM_ID: "GTM-XXXX", // Tag Manger
};

/*
	Init GA
*/

GoogleAnalytics.initialize({
	trackingId: settings.GA_ID,
	debug: true,
});

export default settings;
