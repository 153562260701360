import React, { Component } from "react";
import { Data } from "../../_core/models";
import { DataRouter } from "../../_core/controllers";

import Layout from "../../templates/_layout";

class Router extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <DataRouter
        routes={[
          // Store an ENTRY and HOME global for any URI
          {
            route: "(/.*)/?",
            data: (matches, data) => {
              let found = false;
              let home = false;

              found = data.entries.find(
                (entry) =>
                  entry.uri === window.location.pathname ||
                  entry.uri === window.location.pathname + "/"
              );
              home = data.entries.find((entry) => entry.uri == "/");

              let segments = window.location.hash.split("/");

              let isHome = false;

              if (found && home) isHome = found.id == home.id;

              return {
                SORT: false,
                DETAILS: false,
                FILTERING: false,
                SEARCH: false,
                ENTRY: found,
                HOME: home,
                IS_HOME: isHome,
                SEGMENTS: segments,
              };
            },
          },
          /*{
            route:'^/$',
            data : (matches,data)=> {

              
              let SLIDESHOW = (data.hasOwnProperty('SLIDESHOW')) ? !data.SLIDESHOW : false;
              if(!data.hasOwnProperty('introComplete')) SLIDESHOW = false;

              return {SLIDESHOW:SLIDESHOW}

            }
          },*/
          // Set a SEARCH variable based on a hash value
          {
            route: "/find/(.*)/?",
            data: (matches, data) => {
              let query = decodeURIComponent(matches[1]);

              return { FILTERING: true, FILTER: false, SEARCH: query };
            },
          },
          // Set a FILTER variable based on a hash value
          {
            route: "/filter/(.*)/?",
            data: (matches, data) => {
              let found = false;

              // Lookup the filter category based on slug
              found = data.entries.find(
                (entry) => entry.type == "filters" && entry.slug == matches[1]
              );

              return { FILTERING: true, FILTER: found };
            },
          },
          // Set a DETAILS variable based on a hash value
          {
            route: "/details/(.*)/?",
            data: (matches, data) => {
              let found = false;

              // Lookup the filter category based on slug
              found = data.entries.find(
                (entry) => entry.type == "locations" && entry.slug == matches[1]
              );

              return { DETAILS: true, DETAIL: found };
            },
          },
          // Set a DETAILS variable based on a hash value
          {
            route: "/sort/(.*)/?",
            data: (matches, data) => {
              return { SORT: matches[1] };
            },
          },
          // Set a TEMPLATE variable based on a hash value
          {
            route: "#/template/(.*)/?",
            data: (matches, data) => {
              let found = false;
              let home = false;

              found = data.entries.find((entry) => entry.uri == matches[1]);
              home = data.entries.find((entry) => entry.uri == "/");

              return { TEMPLATE: matches[1] };
            },
          },
        ]}
      >
        <Data require={["HOME"]}>{() => <Layout />}</Data>
      </DataRouter>
    );
  }
}

export default Router;
